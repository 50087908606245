import Vue from 'vue'
import Vuex from 'vuex';
import moment from 'moment';
import RRuleGenerator from "./components/RRule/RRuleGenerator";
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min';

import confetti from "canvas-confetti"
import MassInsertOperatingDays from "./components/Supplier/MassInsertOperatingDays";
import SupplierOperatingDays from "./components/Supplier/SupplierOperatingDays";
import OrderForm from "./components/Sales/Orders/OrderForm";
import DropdownSelect from "./components/Dropdown";
import store from './store/store';

import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/droppable.js';
import 'jquery-ui/ui/widgets/sortable.js';
import ProductBundles from "./components/Product/ProductBundles";
import EmptyingCalendar from "./components/Contract/EmptyingCalendar";
import SupplierRating from "./components/Supplier/SupplierRating";
import WebshopPresencePage from "./components/WebshopPresence/WebshopPresence";
import LoadingButton from "./components/LoadingButton";

require('./bootstrap');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl.min')
require('jquery-ujs');
require('date-input-polyfill');
require('bootbox/dist/bootbox.locales.min');
require('datatables.net-dt/js/dataTables.dataTables.min');
require('select2/dist/js/select2.min');
require('select2/dist/js/i18n/nl.js');
const jsCollection = require('./collection').default;

window.Vue = Vue;
window.Vuex = Vuex
window.bootbox = require('bootbox/dist/bootbox.min');
window.AdminModal = require('./modal').default;
window.RRuleGenerator = RRuleGenerator;
window.Pbf = require('pbf');
window.geobuf = require('geobuf');
window.confetti = confetti;
window.moment = moment;

Vue.component('rrule-generator', RRuleGenerator);
Vue.component('order-form', OrderForm);
Vue.component('product-bundles', ProductBundles);
Vue.component('mass-insert-operating-days', MassInsertOperatingDays);
Vue.component('supplier-operating-days', SupplierOperatingDays);
Vue.component('dropdown-select', DropdownSelect);
Vue.component('loading-button', LoadingButton);
Vue.component('emptying-calendar', EmptyingCalendar);
Vue.component('supplier-rating', SupplierRating);
Vue.component('webshop-presence-page', WebshopPresencePage);

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

new Vue({
    el: '#app',
    store: store,
});
window.Vue = Vue;

const fireConfetti = (amount = 300) => {
    confetti({
        particleCount: amount,
        startVelocity: 80,
        angle: 60,
        spread: 55,
        origin: {x: 0, y: 1},
    });
    confetti({
        particleCount: amount,
        startVelocity: 80,
        angle: 120,
        spread: 55,
        origin: {x: 1, y: 1},
    });

}
window.fireConfetti = fireConfetti;

window.triggerAlert = function (message = 'Wijzigingen opgeslagen', type = 'success') {
    let $el = $('.js-alert.js-' + type + '-alert');
    $el.find('.js-alert-text').text(message);
    $el.slideDown();
    checkAlerts();
};

window.toastr.options.timeOut = 6000;
window.toastr.options.extendedTimeOut = 6000;
window.toastr.options.closeDuration = 300;
window.toastr.options.closeButton = true;
window.toastr.options.progressBar = true;


window.sendNotification = function (type, message, title = null, url = null) {
    if (url) {
        message = message + ' <a class="btn btn-light d-block btn-sm text-body mt-2" href="' + url + '">Bekijken</a>';
    }

    if (user.enable_notification_sound && !onCall) {
        try {
            const audio = new Audio('/audio/notification.wav');
            audio.volume = user.notification_sound_volume
            audio.play();
        } catch (e) {
        }
    }

    if (type === 'success') {
        toastr.success(message, title);
        return;
    }

    if (type === 'warning') {
        toastr.warning(message, title);
        return;
    }

    if (type === 'danger') {
        toastr.error(message, title);
        return;
    }

    toastr.info(message, title);
};

window.onCallEvent = function (e) {
    $('body').removeClass('animateBars');

    onCall = true;

    if (e.status === 'hold') {
        $('.js-callbar').addClass('callBar-hold');
        $('.js-callbar-status').text('In de wacht:');
    } else {
        $('.js-callbar').removeClass('callBar-hold');
        $('.js-callbar-status').text('In gesprek met:');
    }

    var modal = window.AdminModal = window.AdminModal || AdminModal.Instance();

    $('.js-callbar-open-btn').show();
    $('.js-callbar-open-btn').off('click');
    $('.js-callbar-open-btn').on('click', function () {
        modal.Load(e.url, false, 'modal-2xl');
    });

    $('.js-callbar-close').on('click', function () {
        $.ajax({
            url: e.endCallUrl,
            method: 'POST'
        })
    });

    if (e.customer) {
        if (e.customer.company.name) {
            $('.js-callbar-company').text('van ' + e.customer.company.name);
        }

        if (e.isProspect) {
            $('.js-callbar-name').text("Prospect " + e.customer.name);
            return;
        }

        $('.js-callbar-name').text("Klant " + e.customer.name);
        return;
    }

    if (e.employee) {
        if (e.supplier) {
            $('.js-callbar-company').text('van ' + e.supplier.name);
        }

        $('.js-callbar-name').text("Medewerker " + e.employee.full_name);
        return;
    }

    if (e.supplier) {
        $('.js-callbar-name').text("Leverancier " + e.supplier.name);
        return;
    }

    $('.js-callbar-name').text("Onbekend " + e.phoneNumber);
};

function requestCallback(params, $select)
{
    return {search: params.term, type: 'public'};
}

function responseCallback(data, $select, $holder, optionText = 'name')
{
    let items = [];
    if (!$select.attr('required')) {
        items = [{
            id: 0,
            text: $select.attr('data-placeholder') ? $select.attr('data-placeholder') : 'Select',
            disabled: true
        }];
    }
    if (!data.data) {
return items;
    }

    data.data.forEach(item => {
        items.push({id: item.id, text: item[optionText]})
    });

    return {results: items};
}

function withAllAttributesCallback(data, $select, $holder, optionText = 'name')
{
    let items = [];
    if (!$select.attr('required')) {
        items = [{
            id: 0,
            text: $select.attr('data-placeholder') ? $select.attr('data-placeholder') : 'Select',
            disabled: true
        }];
    }
    if (!data.data) {
return items;
    }

    data.data.forEach(item => {
        items.push({...item, id: item.id, text: item[optionText]})
    });

    return {results: items};
}

const initializeSelect2 = function ($select, requestHandler = requestCallback, responseHandler = responseCallback) {

    if ($select.data('select2')) {
        $select.select2('destroy');
    }

    let $holder = $select.closest('.form-collection-item');
    if (!$holder.length) {
$holder = $(this).parents('form');
    }
    const options = {
        dropdownParent: $select.closest('.form-group').length ? $select.closest('.form-group') : null,
        allowClear: true,
        placeholder: "Kies een optie",
        width: '100%',
        ajax: {
            url: $select.attr('data-url'),
            dataType: 'json',
            data: (params) => {
                return requestHandler(params, $select)
            },
            processResults: (data) => {
                return responseHandler(data, $select, $holder)
            }
        }
    };

    $select.select2(options);

};

const initializeLocalSelect2 = function ($select) {

    if ($select.data('select2')) {
        $select.select2('destroy');
    }
    const options = {
        dropdownParent: $select.closest('.form-group').length ? $select.closest('.form-group') : null,
        allowClear: true,
        placeholder: "Kies een optie",
        width: '100%',
    };

    $select.select2(options);
};

let timeOut;

const initializePostcodeApi = () => {
    $(document).on('change', '.js-country-select, .js-municipality-select', function () {
        let $municipalityInput = $('.js-municipality-input');
        let $municipalitySelect = $('.js-municipality-select');
        let $countrySelect = $('.js-country-select');
        let $countryInput = $('.js-country-input');

        if ($(this).closest('.js-collection-child').length) {
            $municipalityInput = $(this).closest('.js-collection-child').find('.js-municipality-input');
            $countryInput = $(this).closest('.js-collection-child').find('.js-country-input');
            $municipalitySelect = $(this).closest('.js-collection-child').find('.js-municipality-select');
            $countrySelect = $(this).closest('.js-collection-child').find('.js-country-select');
        }

        $municipalityInput.val($municipalitySelect.val()).trigger('change');
        $countryInput.val($countrySelect.val()).trigger('change');
    });
    $(document).on('blur', '.js-house-number-input, .js-zip-code-input', function () {
        if (timeOut) {
            clearTimeout(timeOut);
        }
        let $houseNumberInput = $('.js-house-number-input');
        let $zipCodeInput = $('.js-zip-code-input');
        let $streetInput = $('.js-street-input');
        let $municipalityInput = $('.js-municipality-input');
        let $municipalitySelect = $('.js-municipality-select');
        let $cityInput = $('.js-city-input');
        let $countrySelect = $('.js-country-select');

        if ($(this).closest('.js-collection-child').length) {
            $streetInput = $(this).closest('.js-collection-child').find('.js-street-input');
            $municipalityInput = $(this).closest('.js-collection-child').find('.js-municipality-input');
            $municipalitySelect = $(this).closest('.js-collection-child').find('.js-municipality-select');
            $houseNumberInput = $(this).closest('.js-collection-child').find('.js-house-number-input');
            $zipCodeInput = $(this).closest('.js-collection-child').find('.js-zip-code-input');
            $cityInput = $(this).closest('.js-collection-child').find('.js-city-input');
            $countrySelect = $(this).closest('.js-collection-child').find('.js-country-select');
        }

        $cityInput.val('');
        $municipalityInput.val('');
        $streetInput.val('');
        $municipalitySelect.val('').trigger('change');
        $streetInput.attr('readonly', false);
        $cityInput.attr('readonly', false);
        $municipalitySelect.attr('disabled', false);
        $countrySelect.attr('disabled', false);
        let zipCode = $zipCodeInput.val().split(' ').join('');

        if ($houseNumberInput.val() && $zipCodeInput.val() && zipCode.length === 6) {
            timeOut = setTimeout(function () {
                $.get('/company/api/postcode-api/search?zip_code=' + zipCode + '&house_number=' + $houseNumberInput.val(), (res) => {
                    if (res.data && res.data.street) {
                        let location = res.data;
                        $streetInput.val(location.street);
                        $cityInput.val(location.city);
                        $municipalityInput.val(location.municipality_id);
                        $municipalitySelect.val(location.municipality_id).trigger('change');
                        $streetInput.attr('readonly', true);
                        $cityInput.attr('readonly', true);
                        $municipalitySelect.attr('disabled', true);
                        $countrySelect.attr('disabled', true);
                    } else {
                        toastr.error('Geen adres gevonden met deze gegevens');
                    }
                })
            }, 1000);
        }
    })
};

const initializeCustomerAddressSelect = function ($select, requestHandler = requestCallback, responseHandler = responseCallback) {
    function init(val, $select)
    {
        if (val) {
            $select.prop('disabled', false);
            let url = $select.data('url').replace('##', val);
            const options = {
                allowClear: true,
                placeholder: "Kies een optie",
                dropdownParent: $select.closest('.form-group').length ? $select.closest('.form-group') : null,
                ajax: {
                    url: url,
                    dataType: 'json',
                    data: (params) => {
                        return requestHandler(params, $select)
                    },
                    processResults: (data) => {
                        return responseHandler(data, $select, $holder)
                    }
                }
            };
            $select.select2(options);
        } else {
            $select.val(null).trigger('change');
            $select.prop('disabled', 'disabled');
        }
    }

    if ($select.data('select2')) {
        $select.select2('destroy');
    }

    let $holder = $select.closest('.form-collection-item');
    if (!$holder.length) {
$holder = $(this).parents('form');
    }
    if ($('.js-customer-select').val()) {
        init($('.js-customer-select').val(), $select);
    }
    if ($('.js-customer-id').val()) {
        init($('.js-customer-id').val(), $select);
    }

    $('.js-customer-select').on('select2:select', (e) => {
        init(e.params.data.id, $select);
    });

};
window.initializeCustomerAddressSelect = initializeCustomerAddressSelect;
window.initializePostcodeApi = initializePostcodeApi;
window.initializeSelect2 = initializeSelect2;
window.initializeLocalSelect2 = initializeLocalSelect2;
window.initializeAjaxSelects = function () {
    $('.js-product-select, .js-webshop-select, .js-customer-select, .js-supplier-select, .js-product-attribute-price-select, .js-product-category-select, .js-order-select, .js-contract-select').each(function () {
        initializeSelect2($(this), requestCallback, responseCallback);
    });

    $('.js-product-attribute-select').each(function () {
        initializeSelect2($(this), requestCallback, withAllAttributesCallback)
    });

    $('.js-product-category-attribute-select').each(function () {
        initializeSelect2($(this), requestCallback, withAllAttributesCallback)
    });

    $('.js-customer-address-select').each(function () {
        initializeCustomerAddressSelect($(this), requestCallback, responseCallback);
    });

    $('.js-cities-select').each(function () {
        initializeSelect2($(this), requestCallback, withAllAttributesCallback)
    });


    $('.js-quicklinks').click(function (evt) {
        evt.preventDefault();

        $(this).closest('.quicklink').toggleClass('quicklink--active');
    })
};

Vue.filter('toDateString', function (value, format = 'DD-MM-Y') {
    moment.locale('nl');
    return moment(value).format(format)
});

Vue.filter('format_money', function (value, includeCurrency = true, html = false) {
    var decimal = ',';
    var separator = '.';
    if (html) {
        var currency = '&euro;';
    } else {
        var currency = '€';
    }
    value = number_format(value, 2, decimal, separator);
    if (includeCurrency) {
        return currency + ' ' + value;
    }
    return value;
});

function number_format(number, decimals, dec_point, thousands_sep)
{
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function init()
{
    $.fn.select2.defaults.set("language", 'nl');
    $(".select2").each(function () {
        if (!$(this).data('select2-id')) {
            $(this).select2();
        }
    });
    $('.js-datepicker').datepicker();
    jsCollection();
    window.initializeAjaxSelects();
    window.initializePostcodeApi();

    checkAlerts();
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip-wide"]').tooltip({
        container: 'body'
    });

    $('.js-toggleSidebar').click(function (evt) {
        evt.preventDefault();
        var body = $('body');
        if (body.hasClass('sidebarActive')) {
            body.removeClass('sidebarActive');
            $('.nav-item').tooltip('enable')
        } else {
            body.addClass('sidebarActive');
            $('.nav-item').tooltip('disable')
        }
    });

    $('.js-toggleConversationBar').click(function (evt) {
        evt.preventDefault();
        $('body').toggleClass('conversationBarActive');
    })

    $(document).on('input', '.js-kvk-search', function () {
        let route = '/contracts/contracts/search';
        if (window.searchTimeout) {
            clearTimeout(window.searchTimeout);
        }

        window.searchTimeout = setTimeout(() => {
            let searchTerm = $(this).val();
            $.get(route + '?search=' + searchTerm, function (res) {
                $('.js-ts-customers').html(res.ts_customers);
                $('.js-kvk-customers').html(res.kvk_customers);
            })
        }, 700);

        $('.js-search-results').show();
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $(document).on('click', '.has-treeview .nav-link', function (evt) {
        evt.preventDefault();
        $(this).closest('.has-treeview').toggleClass('has-treeview--active');
    });

    if (window.Echo) {
        if (user.call_center_agent_id) {
            console.log('Listening to calls');
            Echo.private(`Call.${user.i}`)
                .listen('IncomingCall', function (e) {
                    $('body').addClass('callBarActive');
                    onCallEvent(e);
                })
                .listen('HoldingCall', function (e) {
                    onCallEvent(e);
                })
                .listen('EndingCall', function () {
                    $('body').removeClass('callBarActive');
                    onCall = false;
                });
        }

        if (user.c) {
            Echo.private(`PaymentCompleted.${user.c}`)
                .listen('PaymentCompleted', function (e) {
                    sendNotification('success', "Nieuwe betaling van " + e.payment.amount + '.', 'Nieuwe betaling', e.url);
                    if (user.enable_confetti_mode) {
                        fireConfetti(e.payment.amount);
                    }
                });
        }

        if (user.i) {
            Echo.private(`notifications.${user.i}`)
                .listen('CallCenterNotification', (e) => {
                    sendNotification(e.type, e.message);
                })
                .listen('NewTaskCreated', (e) => {
                    sendNotification(e.type, e.message, e.title, e.url);
                })
                .listen('TasksUnsnoozed', (e) => {
                    sendNotification(e.type, e.message, e.title, e.url);
                });

            Echo.private(`conversations.${user.i}`)
                .listen('MessageReceived', (e) => {
                    sendNotification('info', e.preview, e.senderName + ' | ' + e.subject, "{{ route('inbox.index') }}");
                });
        }
    } else {
        $(document).ready(function () {
            $('body').addClass('animateBars');
        })
    }
}

function checkAlerts()
{
    $('.js-alert').each(function () {
        //dont auto hide error alerts
        if ($(this).hasClass('js-danger-alert') || $(this).hasClass('js-error-alert')) {
return;
        }
        if ($(this).css('display').length > 0) {
            if ($(this).css('display') !== 'none') {
                setTimeout(() => {
                    $(this).slideUp();
                }, 3000)
            }
        }
    });
}

function parseAllFloat(string)
{
    if (!string) {
        return 0;
    }
    string = string.replace(',','.')

    let decimals = string.split('.');

    let decimalAmount = decimals.length > 1 ? decimals[1].length : null;

    if (decimalAmount === 0) {
        return decimals[0] + '.';
    }

    return parseFloat(parseFloat(string).toFixed(decimalAmount));
}

window.parseAllFloat = parseAllFloat;

window.initModalJavascript = function () {
    init();
};

$(document).ready(init);
