<template>
    <fieldset class="mb-3">
        <div class="form-row">
            <div class="col-sm-3">
                <div class="form-item form-item--radio">
                    <input
                        type="radio"
                        id="month-type-day"
                        name="month-type"
                        value="day"
                        v-model="intMonthType"
                        class="form-check-input"
                    >
                    <label class="form-check-label" for="month-type-day">
                        Specifieke dagen
                    </label>
                </div>
                <div class="form-item form-item--radio">
                    <input
                            type="radio"
                            id="month-type-custom"
                            name="month-type"
                            value="custom"
                            v-model="intMonthType"
                            class="form-check-input"
                    >
                    <label class="form-check-label" for="month-type-custom">
                        Relatief
                    </label>
                </div>
            </div>
            <div class="col-sm-9">
                <div class="calendar-grid" v-if="intMonthType === 'day'">
                    <div class="week first-week">
                        <div :class="monthDayClasses(n)" v-for="n in 7" @click="toggleMonthDay(n)">
                            {{ n }}
                        </div>
                    </div>
                    <div class="week">
                        <div :class="monthDayClasses(n + 7)" v-for="n in 7" @click="toggleMonthDay(n + 7)">
                            {{ n + 7 }}
                        </div>
                    </div>
                    <div class="week">
                        <div :class="monthDayClasses(n + 14)" v-for="n in 7" @click="toggleMonthDay(n + 14)">
                            {{ n + 14 }}
                        </div>
                    </div>
                    <div class="week">
                        <div :class="monthDayClasses(n + 21)" v-for="n in 7" @click="toggleMonthDay(n + 21)">
                            {{ n + 21 }}
                        </div>
                    </div>
                    <div class="week">
                        <div :class="monthDayClasses(n + 28)" v-for="n in 3" @click="toggleMonthDay(n + 28)">
                            {{ n + 28 }}
                        </div>
                        <div class="day no-border" v-for="n in 4"></div>
                    </div>
                </div>
                <div v-else class="form-row">
                    <div class="col-6">
	                    <div class="form-item form-item--compact form-item--compactNoLabel">
	                        <select name="month-count-select" id="month-count-select" v-model="intBySetPos" class="form-control">
	                            <option value="1">Eerste</option>
	                            <option value="2">Tweede</option>
	                            <option value="3">Derde</option>
	                            <option value="4">Vierde</option>
	                            <option value="-1">Laatste</option>
	                        </select>
	                    </div>
                    </div>
                    <div class="col-6">
	                    <div class="form-item form-item--compact form-item--compactNoLabel">
	                        <select name="month-day-select" id="month-day-select" v-model="intMonthDaySelect" class="form-control">
	                            <option v-for="day in $parent.getDays()" :value="day.abbr">{{ day.name.replace(/^\w/, c => c.toUpperCase()) }}</option>
	                            <option value="MO,TU,WE,TH,FR,SA,SU">Dag</option>
	                            <option value="MO,TU,WE,TH,FR">Weekdag</option>
	                            <option value="SA,SU">Weekend dag</option>
	                        </select>
	                    </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</template>

<script>
    export default {
      name: 'month-form',

      props: {
        byDay: Array,
        monthType: String,
        byMonthDay: Array,
        bySetPos: Number,
        monthDaySelect: String
      },

      computed: {
        intMonthType: {
          get () {
            return this.monthType
          },
          set (val) {
            this.$emit('setMonthType', val)
          }
        },

        intBySetPos: {
          get () {
            return this.bySetPos ? this.bySetPos: this.$emit('setBySetPos', 1)
          },
          set (val) {
            this.$emit('setBySetPos', val)
          }
        },

        intMonthDaySelect: {
          get () {
            return this.monthDaySelect
          },
          set (val) {
            this.$emit('setMonthDaySelect', val)
          }
        }
      },

      methods: {
        monthDayClasses (day) {
          let selected = this.byMonthDay.indexOf(day) > -1
          return {
            day: true,
            selected
          }
        },

        toggleMonthDay (day) {
          let index = this.byMonthDay.indexOf(day)

          if (index > -1) {
            return this.$parent.byMonthDay.splice(index, 1)
          }

          this.$parent.byMonthDay.push(day)
        },
      }
    }
</script>
