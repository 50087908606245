function AdminModal() {
    let modal = null;
    let modalEvents = {};

    this.Url = null;

    this.Modal = () => modal;

    this.On = function (event, callback) {
        if (!modalEvents[event]) {
            modalEvents[event] = [];
        }

        modalEvents[event].push(callback);
    };

    this.RemoveListeners = function (event){
        if (!modalEvents[event]){
            return;
        }

        delete modalEvents[event];
    }

    this.Off = function (event, callback) {
        if (!modalEvents[event]) {
            return;
        }
        let index = modalEvents[event].indexOf(callback);
        if (index !== -1) {
            modalEvents[event].splice(index, 1);
        }
    };

    this.fireModalEvent = function (event, response) {
        fireModalEvent(event, response);
    };

    this.OnOnce = function (event, callback) {
        let eventHandler = () => {
            this.Off(event, eventHandler);
            callback.apply(this, arguments);
        };
        this.On(event, eventHandler);
    };

    this.Load = function (url, hideModalOnError, sizeClass = 'modal-lg') {
        this.Url = url;
        return new Promise((resolve, reject) => {
            createModal(sizeClass);

            $.ajax({
                url: url,
                type: 'get',
                success: content => {
                    resolve(content);
                    loadContent(content);
                },
                error: response => {
                    if (hideModalOnError) {
                        setTimeout(() => {
                            modal.modal('hide');
                        });
                    } else {
                        displayModalError(response);
                    }
                    reject(response);
                }
            });

            modal.content.html('');
            modal.modal('show');
            if ($(".js-modal-container").length) {
                $('.modal-backdrop').appendTo('.js-modal-container');
            }
        });
    };

    this.Submit = function (formElm) {
        if (formElm.closest('.modal').length === 0) {
            throw 'Form element is not inside a modal.';
        }
        return submit(formElm[0]);
    };

    this.loadContent = function (content) {
        createModal();

        var html = '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';

        modal.content.html(html);
        modal.content.append(content);
        modal.modal('show');
    };

    function loadContent(content) {
        modal.content.html(content);
        modal.content.find('form:not(.prevent)').submit(onSubmit).addClass('modal-form');
        modal.content.find('[data-toggle="tooltip"]').tooltip();
        fireModalEvent('loaded');
        initModalJavascript();
    }

    // Very simple error modal
    function displayModalError(response) {
        modal.content.html(`
            <div class="modal-body" style="font-size: 2em; color: #c92523; text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <i class="fa fa-exclamation-triangle" style="font-size: 5em;" aria-hidden="true"></i>
                <div style="margin-bottom: -8px;">` + response.statusText + `</div>
            </div>
        `);
    }

    function onSubmit(ev) {
        ev.preventDefault();

        let formElm = ev.target;
        submit(formElm);
    }

    function submit(formElm) {
        let form = $(formElm);

        return new Promise((resolve, reject) => {
            removeErrors(form);

            form.find('input[type="submit"], button, a').addClass('disabled-for-submit disabled').prop('disabled', true);

            $.ajax({
                url: form.attr('action'),
                type: form.attr('method'),
                data: new FormData(formElm),
                dataType: 'JSON',
                processData: false,
                contentType: false,
                success: response => {
                    resolve(response);

                    console.log(response.redirect);

                    if (response.redirect) {
                        window.location = response.redirect;
                        return;
                    }
                    modal.modal('hide');
                    fireModalEvent('hide', response);

                    if (response.message) {
                        toastr.success(response.message);
                    }

                    fireModalEvent('submit', response);
                },
                error: response => {
                    reject(response);
                    displayErrors(form, response.responseJSON);
                    fireModalEvent('error', response.responseJSON);
                    form.find('input[type="submit"], button, a').removeClass('disabled-for-submit disabled').prop('disabled', false);
                }
            });
        });
    }

    function removeErrors(form) {
        form.find('input').removeClass('is-invalid');
        form.find('.invalid-feedback').remove();
        form.find('.form-errors').remove();
        form.find('a.has-error').removeClass('has-error');
    }

    function displayErrors(form, errors) {
        let formErrors = '';
        if (errors.message) {
            formErrors += '<li>' + errors.message + '</li>\n';
        } else if (errors.error) {
            formErrors += '<li>' + errors.error + '</li>\n';
        }
        function showErrors(form, errors){
            for (let attribute in errors) {
                let inputErrors = '';
                for (let message of errors[attribute]) {
                    inputErrors += '<li>' + message + '</li>\n';
                }

                let input = form.find('#' + attribute.replace(/\./g, "_"));
                if (input.length === 0) {
                    formErrors += inputErrors;
                    continue;
                }

                let errorBlock = $(`
                    <div class="invalid-feedback">
                        <ul class="list-unstyled">
                            ` + inputErrors + `
                        </ul>
                    </div>
                `);

                input.addClass('is-invalid');
                input.parent().find('.select2-selection').addClass('is-invalid');

                let inputGroup = input.closest('.input-group');

                if (inputGroup.length > 0) {
                    inputGroup.append(errorBlock);
                } else {
                    input.parent().append(errorBlock);
                }

                let tab = input.closest('.tab-pane');
                let tabId = tab.attr('id');
                if (tabId) {
                    tabId.replace('-tab', '');
                    var tabContent = $('a[href="#' + tabId + '"]');

                    $('.nav-tabs a').removeClass(['active', 'has-error'])
                    $('.tab-pane').removeClass(['active', 'show'])

                    tabContent.addClass(['has-error', 'active']);
                    tab.addClass(['active', 'show']);
                }
            }
            return formErrors;
        }

        if (errors.errors) {
            formErrors = showErrors(form, errors.errors);
        } else {
            formErrors = showErrors(form, errors);
        }

        if (formErrors.length > 0) {
            let formGroup = form.find('.modal-body > .row:first-child');
            if (!formGroup.length) {
                formGroup = form.find('.modal-body > .form-row:first');
            }

            if (!formGroup.length) {
                formGroup = form.parents().find('.form-horizontal');
            }

            formGroup.each(function () {
                $(this).before($(`
                <div class="alert alert-danger form-errors">
                    <ul class="list-unstyled">
                        ` + formErrors + `
                    </ul>
                </div>
            `));
            });
        }

        form.find('.disabled-for-submit').removeClass('disabled-for-submit disabled');
    }

    function createModal(sizeClass = 'modal-lg') {
        if (modal) {
            return modal;
        }

        modal = $(`
            <div id="mainModal" class="modal fade fade-zoom" tabindex="-1" role="dialog" >
                <div class="modal-flex-container">
                    <div class="modal-dialog ${sizeClass}" role="document">
                        <div class="modal-content"></div>
                        <div class="modal-sidebar"></div>
                    </div>
                </div>
            </div>
        `).modal({
            show: false
        });

        if ($(".js-modal-container").length) {
            $(".js-modal-container").append(modal);
        } else {
            $('body .app').append(modal);
        }

        modal.content = modal.find('.modal-content');

        modal
            .on('show.bs.modal', function () {
                fireModalEvent('show');
            })
            .on('hide.bs.modal', function () {
                fireModalEvent('hide');
            })
            .on('shown.bs.modal', function () {
                $(document.body).addClass('modal-open');
                fireModalEvent('shown');
                modal.content.find('input[type=text],textarea,select').filter(':first').focus();
            })
            .on('hidden.bs.modal', function () {
                if ($('.modal.show').length > 0) {
                    $(document.body).addClass('modal-open');
                }
                fireModalEvent('hidden');
            });

        return modal;
    }

    function fireModalEvent(event, args) {
        args = Array.prototype.slice.call(arguments, 1);
        if (modalEvents[event]) {
            for (let callback of modalEvents[event]) {
                callback.apply(this, args);
            }
        }
    }
}

/**
 * Default instance
 * @returns {AdminModal}
 */
function init() {
    let adminModal = new AdminModal();

    $(document).on('click', '[data-modal]', ev => {
        ev.preventDefault();
        ev.stopPropagation();

        let elm = $(ev.currentTarget);

        let url = elm.attr('data-modal');
        let sizeClass = 'modal-lg';
        if (elm.attr('data-modal-size')) {
            sizeClass = elm.attr('data-modal-size');
        }

        adminModal.Load(url, false, sizeClass);
    });

    adminModal.On('submit', () => {
        if (!window.LaravelDataTables) {
            return;
        }

        window.LaravelDataTables.dataTableBuilder.ajax.reload();
    });

    adminModal.On('hide', (e) => {
        if (e && e.modal_url) {
            adminModal.Load(e.modal_url);
            adminModal.Modal().modal('show');
        }
    });

    adminModal.On('shown', function () {
        $(function () {
            $('input[type="file"]').on('change', function (e) {
                const files = e.target.files;

                if (files.length === 1) {
                    var fileName = files[0].name;
                    $(this).next('.custom-file-label').html(fileName);
                }

                if (files.length > 1) {
                    let items = [];

                    for (let i = 0; i < files.length; i++) {
                        items.push(files[i].name);
                    }

                    $(this).next('.custom-file-label').html(items.join(', '));
                }
            });
        });
    })

    return adminModal;
}

var modal = init();
modal.Instance = function () {
    return new AdminModal();
};

export default modal;


