<template>
    <fieldset class="mb-3">
        <div class="row">
            <div class="col-sm-3">
                <div class="form-item form-item--radio">
                    <input
                        type="radio"
                        id="year-type-once"
                        name="year-type"
                        value="once"
                        v-model="intYearType"
                        class="form-check-input"
                    >
                    <label class="form-check-label" for="year-type-once">
                        Op
                    </label>
                </div>

                <div class="form-item form-item--radio">
                    <input
                        type="radio"
                        id="year-type-in"
                        name="year-type"
                        value="in"
                        v-model="intYearType"
                        class="form-check-input"
                    >
                    <label class="form-check-label" for="year-type-in">
                        In
                    </label>
                </div>

                <div class="form-item form-item--radio">
                    <input
                        type="radio"
                        id="year-type-custom"
                        name="year-type"
                        value="custom"
                        v-model="intYearType"
                        class="form-check-input"
                    >
                    <label class="form-check-label" for="year-type-custom">
                        Specifiek
                    </label>
                </div>
            </div>
            <div class="col-sm-9">
                <div v-if="intYearType === 'once'">
                    <div class="form-row">
                        <div class="col-6">
	                        <div class="form-item form-item--compact form-item--compactNoLabel">
	                            <select name="year-month-day" id="year-month-day" v-model="intByMonthDay" class="form-control">
	                                <option v-for="n in 31" :value="n">{{ n }}</option>
	                            </select>
	                        </div>
                        </div>
                        <div class="col-6">
	                        <div class="form-item form-item--compact form-item--compactNoLabel">
	                            <select name="year-month" id="year-month" v-model="intByMonth" class="form-control">
	                                <option v-for="(val, index) in monthOptions()" :value="index">{{ val }}</option>
	                            </select>
	                        </div>
                        </div>
                    </div>

                </div>

                <div v-if="intYearType === 'in'">
                    <div class="rrule-setup-year-grid months">
                        <div
                            v-for="(month, index) in months()"
                            :key="index"
                            :class="yearMonthClasses(parseInt(index))"
                            @click="toggleYearMonth(parseInt(index))"
                            v-text="month"
                        ></div>
                    </div>
                </div>

                <div v-if="intYearType === 'custom'">
                    <div class="form-row">
                        <div class="col">
                            <div class="form-item form-item--compact form-item--compactNoLabel">
                                <select name="month-count-select" id="month-count-select" v-model="intBySetPos" class="form-control">
                                    <option value="1">Eerste</option>
                                    <option value="2">Tweede</option>
                                    <option value="3">Derde</option>
                                    <option value="4">Vierde</option>
                                    <option value="-1">Laatste</option>
                                </select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-item form-item--compact form-item--compactNoLabel">
                                <select name="month-day-select" id="month-day-select" v-model="intMonthDaySelect" class="form-control">
                                    <option v-for="day in $parent.getDays()" :value="day.abbr">{{ day.name.replace(/^\w/, c => c.toUpperCase()) }}</option>
                                    <option :value="'MO,TU,WE,TH,FR,SA,SU'">Dag</option>
                                    <option :value="'MO,TU,WE,TH,FR'">Werkdag</option>
                                    <option :value="'SA,SU'">Weekend dag</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-item form-item--compact form-item--compactNoLabel">
	                    <div class="input-group">
	                        <div class="input-group-prepend">
	                            <span class="input-group-text">in</span>
	                        </div>
	                        <select name="year-month-select" id="year-month-select" v-model="intByMonth" class="form-control">
	                            <option v-for="(month, index) in months()" :value="index">
	                                {{ month }}
	                            </option>
	                        </select>
	                    </div>
                    </div>

                </div>
            </div>
        </div>
    </fieldset>
</template>

<script>
    import months from "./mixins/months";

    export default {
      name: 'year-form',

      mixins: [months],

      props: {
        yearType: String,
        byMonthDay: Array,
        byMonth: Array,
        bySetPos: Number,
        monthDaySelect: String,
      },

      computed: {
        intYearType: {
          get () {
            return this.yearType
          },
          set (val) {
            this.$emit('setYearType', val)
          }
        },

        intByMonthDay: {
          get () {
            return this.byMonthDay[0] || this.$emit('setByMonthDay', 1)
          },
          set (val) {
            this.$emit('setByMonthDay', val)
          }
        },

        intByMonth: {
          get () {
            return this.byMonth[0] || this.$emit('setByMonth', 1)
          },
          set (val) {
            this.$emit('setByMonth', val)
          }
        },

        intBySetPos: {
          get () {
            return this.bySetPos || this.$emit('setBySetPos', 1)
          },
          set (val) {
            this.$emit('setBySetPos', val)
          }
        },

        intMonthDaySelect: {
          get () {
            return this.monthDaySelect || this.$emit('setMonthDaySelect', "MO,TU,WE,TH,FR,SA,SU")
          },
          set (val) {
            this.$emit('setMonthDaySelect', val)
          }
        }
      },

      methods: {
        monthOptions (key = null) {
          return this.months(key)
        },

        yearMonthClasses (month) {
          let selected = this.byMonth.indexOf(month) > -1
          return {
            month: true,
            selected
          }
        },

        toggleYearMonth (month) {
          let index = this.byMonth.indexOf(month)

          if (index > -1) {
            return this.$parent.byMonth.splice(index, 1)
          }

          this.$parent.byMonth.push(month)
        },
      }
    }
</script>
